import { useEffect } from 'react';

const useTitle = (title) => {
  useEffect(() => {
    const oldTitle = document.title;
    document.title = "kneiken.app | "+title;

    return () => document.title = oldTitle
  }, [title])
}

export default useTitle;
import { useGetTenantQuery } from '../../redux/api/tenant.js';
import TenantForm from './TenantForm.jsx';
import TenantSharing from './TenantSharing.jsx';
import PulseLoader from 'react-spinners/PulseLoader';

const Tenant = () => {

  const { data, isFetching } = useGetTenantQuery();
  
  if (isFetching) return <><PulseLoader /></>

  if (!data) return <div>Error: Ingen organisasjon funnet.</div>;

  const { tenant, sharing } = data;

  console.log(data);

  const content =
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Organisasjon</h1>

        <TenantForm tenant={tenant} />

        <TenantSharing sharing={sharing} />

      </div>
    </>

  return content;
}

export default Tenant;
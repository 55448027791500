import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../redux/api/users.js';
import ProfileAvatar from '../../components/ProfileAvatar.jsx';
import Badge from '../../components/Badge.jsx';

const User = ({ userId }) => {

    const user = useSelector(state => selectUserById(state, userId))

    const navigate = useNavigate()

    if (user) {
        /*const created = new Date(note.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(note.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })*/

        const handleEdit = () => navigate(`/users/${userId}`)

        return (
          <div className='border-2 rounded-md bg-white p-2'>
            <ProfileAvatar className=' ' style={{ "--avatar-size": "48px"}} firstName={user.profile.firstName} lastName={user.profile.lastName} />
            <div className='text-xl font-bold'>{user.profile.firstName} {user.profile.lastName}</div>
            <div className=''>{user.workouts?.length || '0'} stk</div>
            <div className=''><a href={`mailto:${user.emailAddresses[0].emailAddress}`}>{user.emailAddresses[0].emailAddress}</a>{!user.emailAddresses[0].verifiedDate ? <Badge title={'Verifisert'} /> : null }</div>
          </div>
        )

    } else return null
}
export default User
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoginToken } from '../../redux/auth/authSlice.js';
import { useSessionCreateMutation } from '../../redux/api/auth.js';
import ScaleLoader from "react-spinners/ScaleLoader";


const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sessionCreate, { data, isLoading, error }] = useSessionCreateMutation();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (data) {
      const loginToken = data;
      dispatch(setLoginToken({ loginToken, emailAddress }));
      setEmailAddress('');
      setPassword('');
      navigate('/verify');
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      if (!error.data?.message) {
        console.error('An unknown error occurred:', error);
        return setErrorMessage('En ukjent feil oppstod. Vennligst prøv igjen senere eller ta kontakt med support.');
      }
      setErrorMessage(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    setErrorMessage(null);
  }, [emailAddress, password]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      sessionCreate({ emailAddress, password });
    }
  }
  
  const handleEmailAddressChange = (e) => setEmailAddress(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const canSubmit = [emailAddress, password].every(Boolean) && !isLoading;

  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="emailAddress" className="block text-sm/6 font-medium text-gray-900">
              E-postadresse
            </label>
            <div className="mt-2">
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                required
                autoComplete="email"
                value={emailAddress}
                onChange={handleEmailAddressChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                Passord
              </label>
              <div className="text-sm">
                <Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Glemt passordet?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          {errorMessage &&
            <div className="flex items-center justify-between text-center border border-red-600 bg-red-50 rounded p-2">
              <p className="text-red-500 text-sm/6">{errorMessage}</p>
            </div>
          }

          <div>
            <button
              type="submit"
              disabled={!canSubmit}
              className="flex w-full justify-center rounded-md bg-[#E29578] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm disabled:opacity-50 hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isLoading ? <ScaleLoader color={"#FFF"} /> : 'Logg på'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default LoginForm
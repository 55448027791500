import CreateWorkoutForm from './CreateWorkoutForm';

const CreateWorkout = () => {

  return (
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Opprett treningsøkt</h1>

        <CreateWorkoutForm />
      </div>
    </>
  )
}

export default CreateWorkout;
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { baseApi } from "./base.js";

const groupsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState = groupsAdapter.getInitialState();

export const groupsApi = baseApi.injectEndpoints({
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => ({
        url: '/groups',
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: respnseData => {
        const loadedGroups = respnseData.groups.map(group => {
          group.id = group._id;
          return group;
        });
        return groupsAdapter.setAll(initialState, loadedGroups);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
                  { type: 'Group', id: 'LIST' },
                  ...result.ids.map(id => ({ type: 'Group', id }))
              ]
        } else return [{ type: 'Group', id: 'LIST' }]
      }
    }),
    createGroup: builder.mutation({
      query: (group) => ({
        url: '/groups',
        method: 'POST',
        body: group
      }),
      invalidatesTags: [
        { type: 'Group', id: "LIST" }
      ]
    }),
    updateGroup: builder.mutation({
      query: (group) => ({
        url: `/groups/${group.id}`,
        method: 'PATCH',
        body: group
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Group', id: arg.id }
      ]
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/groups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Group', id: arg.id }
      ]
    })
  })
});

export const { useGetGroupsQuery, useCreateGroupMutation, useUpdateGroupMutation, useDeleteGroupMutation } = groupsApi;

export const selectGroupsresult = groupsApi.endpoints.getGroups.select();

const selectGroupsData = createSelector(
  selectGroupsresult,
  groupsResult => groupsResult.data
);

export const {
  selectAll: selectAllGroups,
  selectById: selectGroupById,
  selectIds: selectGroupsIds
} = groupsAdapter.getSelectors(state => selectGroupsData(state) ?? initialState);
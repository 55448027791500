import { useParams } from "react-router-dom";
import { useGetGroupsQuery } from '../../redux/api/groups.js';
import { useGetUsersQuery } from '../../redux/api/users.js';
import EditGroupForm from "./EditGroupForm.jsx";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle.js";

const EditGroup = () => {
  useTitle('Oppdater gruppe');

  const { id } = useParams();

  const { group } = useGetGroupsQuery('groupsList', {
    selectFromResult: ({ data }) => ({
      group: data?.entities[id]
    })
  });

  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map(id => data?.entities[id])
    })
  });
  //const { data: users, error, isLoading: isUsersLoading } = useGetUsersQuery();

  if (!group) return <PulseLoader color={"#FFF"} />

  if (!users) return <PulseLoader color={"#FFF"} />
  console.log(users);
  
  return (
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Oppdater gruppe</h1>

        <EditGroupForm group={group} users={users} />
      </div>
    </>
  );
}

export default EditGroup;
//import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { baseApi } from "./base.js";

/*const invitesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.createdDate.localeCompare(b.createdDate)
});*/

//const initialState = invitesAdapter.getInitialState();

export const tenantsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTenant: builder.query({
      query: () => ({
        url: `/tenants/`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        //const tenant = response.tenant;
        //tenant.id = tenant._id;
        return response.sharing;
      },
      providesTags: ['Tenant']
    }),
    updateTenant: builder.mutation({
      query: (tenant) => ({
        url: `/tenants/`,
        method: 'PATCH',
        body: tenant
      }),
      invalidatesTags: ['Tenant']
    }),
    regenerateSharingKey: builder.mutation({
      query: (tenant) => ({
        url: `/tenants/sharekey`,
        method: 'POST',
        body: tenant
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        //const tenant = response.tenant;
        //tenant.id = tenant._id;
        return response;
      },
      invalidatesTags: ['Tenant']
    }),
    getTenantBySharekey: builder.query({
      query: (sharekey) => ({
        url: `/tenants/sharekey/${sharekey}`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        const tenant = response.tenant;
        tenant.id = tenant._id;
        return response.tenant;
      }
    })
  })
});

export const { useGetTenantQuery, useUpdateTenantMutation, useRegenerateSharingKeyMutation, useGetTenantBySharekeyQuery } = tenantsApi;

//export const selectInvitesResult = invitesApi.endpoints.getInvites.select();

/*const selectInvitesData = createSelector(
  selectInvitesResult,
  invitesResult => invitesResult.data
);*/

/*export const {
  selectAll: selectAllInvites,
  selectById: selectInvitesById,
  selectIds: selectInvitesIds
} = invitesAdapter.getSelectors(state => selectInvitesData(state) ?? initialState);*/
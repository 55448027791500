export default function Alert({ message, type = 'error' }) {

  const data = {}
  switch (type) {
    case 'error':
      data.title = 'En feil oppstod'
      break
  }
  return (
    <div className={`w-full text-md text-gray-900 ${(type === 'error' ? 'bg-orange-100 border-orange-500 text-orange-700' : 'bg-green-100 border-green-500 text-green-700')} border-l-4 p-4 mb-7`} role="alert">
      <p className="font-bold">En feil oppstod</p>
      <p>{message}</p>
    </div>
  )
}

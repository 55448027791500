import { useState, useEffect } from 'react';
import { useCreateWorkoutMutation } from '../../redux/api/workouts.js';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";

const CreatWorkoutForm = () => {
  const navigate = useNavigate();

  const [ createWorkout, { isFetching, isSuccess, error } ] = useCreateWorkoutMutation();

  const [duration, setDuration] = useState(30);
  const [workoutDate, setWorkoutDate] = useState(new Date().toISOString().split('T')[0]);
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setDuration(60);
      setWorkoutDate(new Date().toISOString().split('T')[0]);
      setNotes('');
      navigate('/workouts');
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (error) {
      if (!error.data?.message) {
        console.error('An unknown error occurred:', error);
        return setErrorMessage('En ukjent feil oppstod. Vennligst prøv igjen senere eller ta kontakt med support.');
      }
      setErrorMessage(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    setErrorMessage('');
  }, [duration, workoutDate, notes]);

  const handleDurationChange = e => setDuration(e.target.value);

  const handleWorkoutDateChange = e => setWorkoutDate(e.target.value);

  const handleNotesChange = e => setNotes(e.target.value);

  const canSubmit = [duration, workoutDate].every(Boolean) && !isFetching;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await createWorkout({ duration, workoutDate, notes });
    }
  }

  const subtractDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate.toISOString().split('T')[0];
  };

  const twentyOneDaysAgo = subtractDays(new Date(), 21);

  return (
    <>
      <form onSubmit={handleSubmit} className=''>

        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Ny treningsøkt</h2>
            <p className='mt-1 text-sm/6 text-gray-600'>Legg inn treningsøkten din så du er med i kampanjene. Vær obs på at du kan kun legge inn treningsøkter for i dag og 3 uker tilbake i tid.</p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="col-span-full">
                <label htmlFor="duration" className="block text-sm/6 font-medium text-gray-900">Varighet</label>
                <div className="mt-2">
                  <input
                    id="duration"
                    name="duration"
                    type="number"
                    placeholder=""
                    value={duration}
                    onChange={handleDurationChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />  
                </div>
                <p className="text-sm/6 text-gray-600">Kun økter med varighet på 30 minutter eller mer teller med i trekninger og rangeringer.</p>
              </div>

              <div className="col-span-full">
                <label htmlFor="workoutDate" className="block text-sm/6 font-medium text-gray-900">Dato</label>
                <div className="mt-2">
                  <input
                    id="workoutDate"
                    name="workoutDate"
                    type="date"
                    placeholder=""
                    value={workoutDate}
                    onChange={handleWorkoutDateChange}
                    min={twentyOneDaysAgo}
                    max={new Date().toISOString().split('T')[0]}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />  
                </div>
                <p className="text-sm/6 text-gray-600">Du kan kun legge inn treningsøkter for i dag og 3 uker tilbake i tid.</p>
              </div>

              <div className="col-span-full">
                <label htmlFor="notes" className="block text-sm/6 font-medium text-gray-900">Notater</label>
                <div className="mt-2">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={2}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    value={notes}
                    onChange={handleNotesChange}
                  />
                </div>
                <p className="text-sm/6 text-gray-600">Notater er kun synlig for deg selv.</p>
              </div>

            </div>
          </div>
        </div>

        {errorMessage &&
            <div className="flex items-center justify-between text-center border border-red-600 bg-red-50 rounded p-2">
              <p className="text-red-500 text-sm/6">{errorMessage}</p>
            </div>
          }

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >
            {isFetching ? <ScaleLoader color={"#FFF"} /> : 'Lagre treningsøkt'}
          </button>
        </div>
      </form>
    </>
  );
}

export default CreatWorkoutForm;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectWorkoutById, useDeleteWorkoutMutation } from '../../redux/api/workouts.js';

const Workout = ({ workoutId }) => {
  const workout = useSelector(state => selectWorkoutById(state, workoutId))
  const [deleteWorkout] = useDeleteWorkoutMutation();
  const navigate = useNavigate();

  if (workout) {
    const workoutDate = new Date(workout.workoutDate).toLocaleString('nn-NO', { day: 'numeric', month: 'long' });

    const handleEdit = () => navigate(`/workouts/${workoutId}`);

    const handleDelete = async () => {
      if (window.confirm('Er du sikker på at du vil slette denne treningsøkten?')) {
        await deleteWorkout(workoutId);
      }
    }

    return (
      <tr className=''>
        <td className=''>{workoutDate}</td>
        <td className=''>{workout.duration} m</td>
        <td className="table__cell"><button className='' onClick={handleEdit}><FontAwesomeIcon icon={faPenToSquare} /></button> <button className='' onClick={handleDelete}><FontAwesomeIcon icon={faTrashCan} /></button></td>
      </tr>
    )

  } else return null;
}

export default Workout;
import { useParams } from "react-router-dom";
import { useGetWorkoutsQuery } from '../../redux/api/workouts.js';
//import { useGetWorkoutTypesQuery } from '../../redux/api/workoutTypes.js';
import EditWorkoutForm from "./EditWorkoutForm";
import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle.js";

const EditWorkout = () => {
  useTitle('Oppdater treningsøkt');
  
  const { id } = useParams();

  const { workout } = useGetWorkoutsQuery('workoutsList', {
    selectFromResult: ({ data }) => ({
      workout: data?.entities[id]
    })
  });

  /*const { workoutTypes } = useGetWorkoutTypesQuery("workoutTypesList", {
    selectFromResult: ({ data }) => ({
      workoutTypes: data?.ids.map(id => data?.entities[id])
    })
  });*/

  //if (!workoutTypes?.length) return <PulseLoader color={"#FFF"} />

  if (!workout) return <PulseLoader color={"#FFF"} />
  
  return (
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Oppdater treningsøkt</h1>

        <EditWorkoutForm workout={workout} /* workoutTypes={workoutTypes}*/ />
      </div>
    </>
  );
}

export default EditWorkout;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../auth/authSlice.js';

const baseQuery = fetchBaseQuery({
  //baseUrl: 'http://localhost:4903',
  //baseUrl: 'https://api.dev.kneiken.app/v1',
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log('401 error, attempting to refresh token');
    const refreshResult = await baseQuery('/sessions/refresh', api, extraOptions);

    console.log('refreshResult', refreshResult);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      const { accessToken, roles } = refreshResult.data;
      console.log('refreshed token', accessToken);
      api.dispatch(setCredentials({ user, accessToken, roles }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
}

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  tagTypes: ['Workout', 'Group', 'Event', 'Contest', 'User', 'Invite', 'Account'],
  endpoints: (builder) => ({}),
});
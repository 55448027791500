import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { emailAddress: null, user: null, loginToken: null, accessToken: null, roles: [] },
  reducers: {
    setLoginToken: (state, action) => {
      const { emailAddress, loginToken } = action.payload;
      state.emailAddress = emailAddress;
      state.loginToken = loginToken;
    },
    setCredentials: (state, action) => {
      const { user, accessToken, roles } = action.payload;
      console.log('setCredentials', action.payload);
      state.loginToken = null;
      state.user = user;
      state.accessToken = accessToken;
      state.roles = roles;
      
    },
    logOut: (state) => {
      state.user = null;
      state.accessToken = null;
      state.roles = [];
    }
  },
});

export const { setLoginToken, setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectLoginToken = (state) => state.auth.loginToken;

export const selectAccessToken = (state) => state.auth.accessToken;
import ScaleLoader from "react-spinners/ScaleLoader";
import ProfileAvatar from "../../components/ProfileAvatar";

const WorkoutSummaryCard = ({data}) => {
  

  if (!data) return <><ScaleLoader /></>;

  const { user, workouts } = data;
  console.log("user: ", data.user);
  console.log("workouts: ", data.workouts);

  return (
    <>
      <div className="w-full bg-white border rounded-md shadow-md my-2 p-3">
        <div className="grid grid-cols-6 mb-4">
          <div className="col-span-1 content-center">
            <ProfileAvatar className='profile-avatar bg-blue-300 text-blue-500' style={{ "--avatar-size": "42px"}} firstName={user.profile.firstName} lastName={user.profile.lastName} />
          </div>
          <div className="col-span-4">
            <div className="text-xl font-bold">{user.profile.firstName} {user.profile.lastName}</div>
            <div className="text-sm">{user.profile.displayName}</div>
          </div>
          <div className="col-span-1 content-center align-middle text-end">
            <div className="text-4xl font-bold">{workouts.length}</div>
          </div>
        </div>
        {/*<div className="bg-red-50">
          test
        </div>*/}
      </div>
    </>
  )
}

export default WorkoutSummaryCard
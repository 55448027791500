import { useState, useEffect } from 'react';
import { useCreateAccountMutation } from '../../redux/api/account.js';
import { useGetTenantBySharekeyQuery } from '../../redux/api/tenant.js';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, Link } from 'react-router-dom';
import PasswordChecker from '../../components/PasswordChecker.jsx';
import { useParams } from "react-router-dom";

const RegisterUserForm = () => {
  const navigate = useNavigate();

  const { shareKey } = useParams();

  const [ createAccount, { data, error: registrationError, isSuccess, isLoading } ] = useCreateAccountMutation();
  const { data: tenant, isFetching, isSuccess: isTenantSuccess } = useGetTenantBySharekeyQuery(shareKey);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);

  const handleLastNameChange = (e) => setLastName(e.target.value);

  const handleEmailAddressChange = (e) => setEmailAddress(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleTermsOfServiceChange = (e) => setTermsOfService(e.target.checked);

  const handlePrivacyPolicyChange = (e) => setPrivacyPolicy(e.target.checked);

  const canSubmit = [firstName, lastName, password, emailAddress, termsOfService, privacyPolicy].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    await createAccount({ tenant: tenant._id, firstName, lastName, emailAddress, password });
  }

  useEffect(() => {
    if (isSuccess) {
      setFirstName('');
      setLastName('');
      setEmailAddress('');
      setPassword('');
      setTermsOfService(false);
      setPrivacyPolicy(false);
      navigate('/login');
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (registrationError) {
      setErrorMessage(registrationError.data.message);
    }
  }, [registrationError]);

  useEffect(() => {
    setErrorMessage(null);
  }, [firstName, lastName, emailAddress, password]);

  if (!isTenantSuccess) return <>feil</>

  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">

          <div>
            <h2 className="text-center text-2xl/9 font-bold tracking-tight text-gray-900">
              Opprett Kneiken-konto
            </h2>

            <h3 className="mt-2 text-center text-gray-600">{tenant?.company.name}</h3>
          </div>

          <div>
            <label htmlFor="firstName" className="block text-sm/6 font-medium text-gray-900">
              Fornavn
            </label>
            <div className="mt-1">
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                autoComplete="given-name"
                value={firstName}
                onChange={handleFirstNameChange}
                autoFocus={true}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="lastName" className="block text-sm/6 font-medium text-gray-900">
              Etternavn
            </label>
            <div className="mt-1">
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                autoComplete="family-name"
                value={lastName}
                onChange={handleLastNameChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="emailAddress" className="block text-sm/6 font-medium text-gray-900">
              E-postadresse
            </label>
            <div className="mt-1">
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                required
                autoComplete="email"
                value={emailAddress}
                onChange={handleEmailAddressChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
              Passord
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="new-password"
                value={password}
                onChange={handlePasswordChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
            <PasswordChecker password={password} />
          </div>

          <div className="mt-6 space-y-6">
            <div className="flex gap-3">
              <div className="flex h-6 shrink-0 items-center">
                <div className="group grid size-4 grid-cols-1">
                  <input
                    id="termsOfService"
                    name="termsOfService"
                    type="checkbox"
                    required
                    aria-describedby="termsOfService-description"
                    checked={termsOfService}
                    onChange={handleTermsOfServiceChange}
                    className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                  />
                  <svg
                    fill="none"
                    viewBox="0 0 14 14"
                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                  >
                    <path
                      d="M3 8L6 11L11 3.5"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="opacity-0 group-has-[:checked]:opacity-100"
                    />
                    <path
                      d="M3 7H11"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="opacity-0 group-has-[:indeterminate]:opacity-100"
                    />
                  </svg>
                </div>
              </div>
              <div className="text-sm/6">
                <label htmlFor="termsOfService" className="font-medium text-gray-900">
                  Jeg godtar vilkårene for bruk
                </label>
                <p id="termsOfService-description" className="text-gray-500">
                  Link til vilkårene for bruk.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 space-y-6">
            <div className="flex gap-3">
              <div className="flex h-6 shrink-0 items-center">
                <div className="group grid size-4 grid-cols-1">
                  <input
                    id="privacyPolicy"
                    name="privacyPolicy"
                    type="checkbox"
                    required
                    aria-describedby="privacyPolicy-description"
                    checked={privacyPolicy}
                    onChange={handlePrivacyPolicyChange}
                    className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                  />
                  <svg
                    fill="none"
                    viewBox="0 0 14 14"
                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                  >
                    <path
                      d="M3 8L6 11L11 3.5"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="opacity-0 group-has-[:checked]:opacity-100"
                    />
                    <path
                      d="M3 7H11"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="opacity-0 group-has-[:indeterminate]:opacity-100"
                    />
                  </svg>
                </div>
              </div>
              <div className="text-sm/6">
                <label htmlFor="privacyPolicy" className="font-medium text-gray-900">
                  Jeg godtar personvernerklæringen
                </label>
                <p id="privacyPolicy-description" className="text-gray-500">
                  Link til personvernerklæringen.
                </p>
              </div>
            </div>
          </div>

          {errorMessage && <p className="text-red-500 text-sm/6">{errorMessage}</p>}

          <div>
            <button
              type="submit"
              disabled={!canSubmit}
              className="flex w-full justify-center rounded-md bg-[#E29578] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm disabled:opacity-50 hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isLoading ? <ScaleLoader color={"#FFF"} /> : 'Opprett Kneiken-konto'}
            </button>
          </div>

        </form>
      </div>
    </>
  )
}

export default RegisterUserForm;
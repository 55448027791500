import { baseApi } from "./base.js";

export const accountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: (user) => ({
        url: '/account',
        method: 'POST',
        body: user
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      invalidatesTags: ['Invite', 'Account']
    }),
    getAccount: builder.query({
      query: () => ({
        url: `/account/`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        const user = response.user;
        user.id = user._id;
        return user;
      },
      providesTags: ['Account']
    }),
    updateAccount: builder.mutation({
      query: (user) => ({
        url: `/account/`,
        method: 'PATCH',
        body: user
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      invalidatesTags: ['User', 'Account']
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: `/account/password/`,
        method: 'PATCH',
        body: { oldPassword: data.oldPassword, password: data.newPassword }
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      }
    }),
    verifyEmail: builder.mutation({
      query: (verificationToken) => ({
        url: `/account/verify-email/${verificationToken}`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      }
    }),
    forgotPassword: builder.mutation({
      query: (emailAddress) => ({
        url: '/account/forgot-password',
        method: 'POST',
        body: { emailAddress }
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      }
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/account/reset-password/`,
        method: 'POST',
        body: data
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      }
    }),
    getTOTPSecret: builder.query({
      query: () => ({
        url: `/account/totp/`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        console.log(response);
        //const user = response.user;
        //user.id = user._id;
        return response.data;
      },
    }),
    /*,
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id }
      ]
    })*/
  })
});

export const { useCreateAccountMutation, useVerifyEmailMutation, useForgotPasswordMutation, useResetPasswordMutation, useGetAccountQuery, useUpdateAccountMutation, useUpdatePasswordMutation,  useGetTOTPSecretQuery } = accountApi;
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { baseApi } from "./api/base.js";
import authReducer from "./auth/authSlice";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  //[authApi.reducerPath]: authApi.reducer,
  /*baseApi: baseApi.reducer,
  authApi: authApi.reducer,*/
  auth: authReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    { serializableCheck: false }
  ).concat(baseApi.middleware)
});

export const persistor = persistStore(store);
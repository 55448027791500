import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectGroupById } from '../../redux/api/groups.js';

const GroupCard = ({ groupId }) => {
  const group = useSelector(state => selectGroupById(state, groupId));
  const navigate = useNavigate();

  if (group) {
    const handleView = () => navigate(`/groups/${groupId}`);

    const handleEdit = () => navigate(`/groups/${groupId}/edit`);

    return (
      <li className="flex justify-between gap-x-6 p-2 border-2 rounded my-2 shadow bg-white">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-lg/6 font-semibold text-gray-900">
              <Link to={`/groups/${groupId}`}>{group.name}</Link>
              <button className='ms-4' onClick={handleEdit}><FontAwesomeIcon icon={faPenToSquare} /></button>
              {/*
              <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                <div className="size-1.5 rounded-full bg-emerald-500"></div>
              </div>
              <p className="text-xs/5 text-gray-500">Online</p>
              */}
            </p>
            <p className="mt-1 truncate text-md/5 text-gray-500">{group.description || 'Ingen beskrivelse.'}</p>
          </div>
          {/*<div className='text-xl font-bold' ></div>
          <button className='' onClick={handleEdit}><FontAwesomeIcon icon={faPenToSquare} /></button>*/}
        </div>
        {
        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
          <p className="text-sm/6 text-gray-900">{group.members?.length || 0} medlemmer</p>
        </div>
        }
      </li>
    )

  } else return null
}

export default GroupCard;
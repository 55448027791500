import { useState, useEffect } from "react";
import { useUpdateTenantMutation } from '../../redux/api/tenant.js';
import ScaleLoader from "react-spinners/ScaleLoader";

const TenantForm = ({ tenant }) => {


  const [updateTenant, { isFetching, isSuccess }] = useUpdateTenantMutation();

  const [name, setName] = useState(tenant.company.name);

  useEffect(() => {
    if (isSuccess) {
      setName(tenant.company.name);
    }
  }, [tenant.company.name, isSuccess]);

  const handleNameChange = (e) => setName(e.target.value);

  const canSubmit = [name].every(Boolean) && !isFetching;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await updateTenant({ name });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Organisasjonsinformasjon</h2>
            <p className='mt-1 text-sm/6 text-gray-600'></p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="col-span-full">
                <label htmlFor="name" className="block text-sm/6 font-medium text-gray-900">Navn</label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >
            {isFetching ? <ScaleLoader color={"#FFF"} /> : 'Oppdater organisasjon'}
          </button>
        </div>

      </form>
    </>
  );
}

export default TenantForm;
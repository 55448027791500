import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { baseApi } from "./base.js";

const usersAdapter = createEntityAdapter({
  //sortComparer: (a, b) => a.profile.firstName.localeCompare(b.profile.firstName)
});

const initialState = usersAdapter.getInitialState();

export const usersApi = baseApi.injectEndpoints({
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: (response) => {
        const user = response.users[0];
        console.log(user);
        user.id = user._id;
        return usersAdapter.setOne(initialState, user);
      },
      providesTags: (result, error, arg) => [{ type: 'User', id: arg }]
    }),
    getUsers: builder.query({
      query: () => ({
        url: '/users',
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: respnseData => {
        const loadedUsers = respnseData.users.map(user => {
          user.id = user._id;
          return user;
        });
        return usersAdapter.setAll(initialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
                  { type: 'User', id: 'LIST' },
                  ...result.ids.map(id => ({ type: 'User', id }))
              ]
        } else return [{ type: 'User', id: 'LIST' }]
      }
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: user
      }),
      invalidatesTags: [
        { type: 'User', id: "LIST" }
      ]
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id }
      ]
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id }
      ]
    })
  })
});

export const { useGetUserQuery, useGetUsersQuery, useCreateUserMutation, useUpdateUserMutation } = usersApi;

export const selectUsersResult = usersApi.endpoints.getUsers.select();

const selectUsersData = createSelector(
  selectUsersResult,
  usersResult => usersResult.data
);

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUsersIds
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState);
import { useState, useEffect } from 'react';
import { useCreateGroupMutation } from '../../redux/api/groups.js';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";

const CreateGroupForm = () => {

  const navigate = useNavigate();

  const [createGroup, { isFetching, isSuccess, error }] = useCreateGroupMutation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setName('');
      setDescription('');
      setNotes('');
      navigate('/groups');
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (error) {
      if (!error.data?.message) {
        console.error('An unknown error occurred:', error);
        return setErrorMessage('En ukjent feil oppstod. Vennligst prøv igjen senere eller ta kontakt med support.');
      }
      setErrorMessage(error.data.message);
    }
  }, [error]);

  const handleNameChange = (e) => setName(e.target.value);
  
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  
  const handleNotesChange = (e) => setNotes(e.target.value);

  const canSubmit = [name].every(Boolean) && !isFetching;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await createGroup({ name, description, notes });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className=''>
        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="col-span-full">
                <label htmlFor="name" className="block text-sm/6 font-medium text-gray-900">Navn</label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    minLength={2}
                    maxLength={64}
                    onChange={handleNameChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />  
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">Beskrivelse</label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    minLength={2}
                    maxLength={255}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
                <p className="text-sm/6 text-gray-600">Gi gruppen en god beskrivelse så medlemmer forstår hva den handler om eller hvem den inkluderer.</p>
              </div>

              <div className="col-span-full">
                <label htmlFor="notes" className="block text-sm/6 font-medium text-gray-900">Notater</label>
                <div className="mt-2">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={2}
                    minLength={2}
                    maxLength={255}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    value={notes}
                    onChange={handleNotesChange}
                  />
                </div>
                <p className="text-sm/6 text-gray-600">Legg inn notater om gruppen som kun administratorer kan se.</p>
              </div>

            </div>
          </div>
        </div>

        {errorMessage &&
            <div className="flex items-center justify-between text-center border border-red-600 bg-red-50 rounded p-2">
              <p className="text-red-500 text-sm/6">{errorMessage}</p>
            </div>
          }

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >
            {isFetching ? <ScaleLoader color={"#FFF"} /> : 'Lagre gruppe'}
          </button>
        </div>
      </form>
    </>
  );
}

export default CreateGroupForm;
import { useState, useEffect } from 'react';
import { useResetPasswordMutation } from '../../redux/api/account.js';
import ScaleLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const ForgotPasswordForm = ({ resetToken }) => {

  const [ resetPassword, { isLoading, isSuccess, isError, error }] = useResetPasswordMutation();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setEmailAddress('');
      setPassword('');
    }
  }, [isSuccess]);

  const handleEmailAddressChange = (e) => setEmailAddress(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const canSubmit = [emailAddress, password].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await resetPassword({ resetToken, emailAddress, password });
    }
  }

  if (isSuccess) return (
    <div className='flex justify-center'>
      <p className='text-xl'><FontAwesomeIcon icon={faCircleCheck} size='lg' className='text-green-500 me-3' />Passordet ditt ble endret! Du kan nå logge inn med det nye passordet ditt.</p>
    </div>
  );

  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="emailAddress" className="block text-sm/6 font-medium text-gray-900">
              E-postadresse
            </label>
            <div className="mt-2">
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                required
                autoComplete="email"
                value={emailAddress}
                onChange={handleEmailAddressChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                Passord
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="new-password"
                value={password}
                onChange={handlePasswordChange}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={!canSubmit}
              className="flex w-full justify-center rounded-md bg-[#E29578] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm disabled:opacity-50 hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isLoading ? <ScaleLoader color={"#FFF"} /> : 'Oppdater passordet'}
            </button>
          </div>
        </form>
      </div>         
    </>
  );
}

export default ForgotPasswordForm;
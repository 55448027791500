import { useState, useEffect } from 'react';
import { useUpdateWorkoutMutation, useDeleteWorkoutMutation } from '../../redux/api/workouts.js';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const EditWorkoutForm = ({ workout }) => {
  const [updateWorkout, { isLoading, isSuccess, error }] = useUpdateWorkoutMutation();

  const [deleteWorkout, { isLoading: isDelLoading, isSuccess: isDelSuccess }] = useDeleteWorkoutMutation();
    
  const navigate = useNavigate();

  // Prepare options for the select element for workoutType
  /*const options = []
  options.push({ value: '', label: 'Ikke oppgitt' });
  if (workoutTypes) {
    workoutTypes.map(type => {
      options.push({ value: type.id, label: type.name })
    });
  }*/
  // find index of workoutType in options
  //const index = options.findIndex(option => option.value === workout.workoutType);

  //const [workoutType, setWorkoutType] = useState((index ? options[index] : options[0]));
  const [duration, setDuration] = useState(workout.duration);
  const [workoutDate, setWorkoutDate] = useState(new Date(workout.workoutDate).toISOString().split('T')[0]);
  const [notes, setNotes] = useState(workout.notes);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      //setWorkoutType()
      setDuration(60)
      setWorkoutDate()
      setNotes()
      navigate('/workouts')
    }
  }, [isSuccess, isDelSuccess, navigate]);

  //const handleWorkoutTypeChange = e => setWorkoutType(e);

  const handleDurationChange = e => setDuration(e.target.value);

  const handleWorkoutDateChange = e => setWorkoutDate(e.target.value);

  const handleNotesChange = e => setNotes(e.target.value);

  const canSubmit = [duration, workoutDate].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await updateWorkout({ id: workout.id, duration, workoutDate, notes })
    }
  }

  const handleDeleteWorkout = async () => {
    if (window.confirm('Er du sikker på at du vil slette denne treningsøkten?')) {
      await deleteWorkout(workout.id);
    }
  }

  

  console.log(workout);

  return (
    <>
      <p>{error?.data?.message}</p>

      <form onSubmit={handleSubmit} className='flex flex-col'>

        <div className='mb-5'>
          <label className='block mb-2 text-sm font-medium' htmlFor='duration'>Varighet</label>
          <input className='text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'  type="number" name="duration" value={duration} onChange={handleDurationChange} />
        </div>

        <div className='mb-5'>
          <label className='block mb-2 text-sm font-medium' htmlFor='workoutDate'>Dato</label>
          <input className='text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'  type="date" name="workoutDate" value={workoutDate} onChange={handleWorkoutDateChange} />
        </div>

        <div className='mb-5'>
          <label className='block mb-2 text-sm font-medium' htmlFor='notes'>Notater</label>
          <textarea className='text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'  name="notes" value={notes} onChange={handleNotesChange} />
        </div>

        <div className="flex justify-center">
          <button type="submit" disabled={!canSubmit} className='me-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'>Oppdater</button>
          <button type="button" disabled={isDelLoading} className='text-white bg-red-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center' onClick={handleDeleteWorkout}>Slett</button>
        </div>
      </form>
    </>
  )
}

export default EditWorkoutForm;
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <>
      <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
            Fant ikke siden
          </h1>
          <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Beklager men den siden du leter etter eksisterer ikke.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Tilbake til startsiden
            </Link>
            <a href="mailto:support@kneiken.app" className="text-sm font-semibold text-gray-900">
              Kontakt brukerstøtte <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
    </>
  )
}

export default NotFoundPage;
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, selectLoginToken } from '../../redux/auth/authSlice.js';
import { useSessionVerifyMutation } from '../../redux/api/auth.js';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from 'react-router-dom';

const VerifyOTPForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginToken = useSelector(selectLoginToken);
  const [sessionVerify, { data, isLoading, error }] = useSessionVerifyMutation();

  const [oneTimeCode, setOneTimeCodee] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOneTimeCodeChange = (e) => setOneTimeCodee(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    sessionVerify({ otpCode: oneTimeCode, loginToken });
  }

  useEffect(() => {
    if (error) {
      if (!error.data?.message) {
        console.error('An unknown error occurred:', error);
        return setErrorMessage('En ukjent feil oppstod. Vennligst prøv igjen senere eller ta kontakt med support.');
      }
      setErrorMessage(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    setErrorMessage(null);
  }, [oneTimeCode]);

  useEffect(() => {
    if (data) {
      const { accessToken, user, roles } = data;
      dispatch(setCredentials({ user, accessToken, roles }));
      setOneTimeCodee('');
      navigate('/');
    }
  }, [data, navigate, dispatch]);

  const canSubmit = [oneTimeCode].every(Boolean) && !isLoading;

  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mb-4 text-center text-sm/6 text-gray-900">En engangskode er sendt til deg på e-post eller SMS. Dersom du har aktivert bruk av engangskode-app finner du koden i denne.</div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="oneTimeCode" className="block text-sm/6 font-medium text-gray-900">
                Engangskode
              </label>
            </div>
            <div className="mt-2">
              <input
                id="oneTimeCode"
                name="oneTimeCode"
                type="text"
                required
                inputMode='numeric'
                autoComplete="one-time-code"
                value={oneTimeCode}
                onChange={handleOneTimeCodeChange}
                maxLength="6"
                placeholder='XXXXXX'
                autoFocus={true}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 text-xl text-center font-mono"
              />
            </div>
          </div>

          {errorMessage &&
            <div className="flex items-center justify-between text-center border border-red-600 bg-red-50 rounded p-2">
              <p className="text-red-500 text-sm/6">{errorMessage}</p>
            </div>
          }

          <div>
            <button
              type="submit"
              disabled={!canSubmit}
              className="flex w-full justify-center rounded-md bg-[#E29578] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm disabled:opacity-50 hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isLoading ? <ScaleLoader color={"#FFF"} /> : 'Bekreft engangskode'}
            </button>
          </div>

          <div className="w-full text-center text-sm">
            <Link to="/login" className="font-semibold text-slate-500 hover:text-slate-700 text-center">
              Start påloggingen på nytt
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default VerifyOTPForm
import { useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRegenerateSharingKeyMutation } from '../../redux/api/tenant.js';

const TenantSharing = ({ sharing }) => {

  const [regenerateSharingKey, { isFetching }] = useRegenerateSharingKeyMutation();

  const [shareKey, setShareKey] = useState(sharing.shareKey);
  const [link, setLink] = useState(sharing.link);
  const [qr, setQr] = useState(sharing.qr);

  const canSubmit = !isFetching;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!window.confirm('Er du sikker på at du vil generere en ny delingslink?')) {
      return
    }

    if (canSubmit) {
      const { data } = await regenerateSharingKey();
      setShareKey(data.shareKey);
      setLink(data.link);
      setQr(data.qr);
    }
  }

  return (
    <>

        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Delingsinformasjon</h2>
            <p className='mt-1 text-sm/6 text-gray-600'></p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="col-span-full">
                <label htmlFor="name" className="block text-sm/6 font-medium text-gray-900">Link</label>
                <div className="mt-2">
                  <p>{shareKey}</p>
                  <p>{link}</p>
                  <img src={qr} alt="QR-kode" className="mt-2" />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            {isFetching ? <ScaleLoader color={"#FFF"} /> : 'Generer ny delingslink'}
          </button>
        </div>
    </>
  );
}

export default TenantSharing;
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { baseApi } from "./base.js";

const workoutsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.workoutDate - a.workoutDate
});

const initialState = workoutsAdapter.getInitialState();

export const workoutsApi = baseApi.injectEndpoints({
  
  endpoints: (builder) => ({
    getWorkouts: builder.query({
      query: (page = 1) => ({
        url: '/workouts?page='+page,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: respnseData => {
        const loadedWorkouts = respnseData.workouts.map(workout => {
          workout.id = workout._id;
          return workout;
        });
        return workoutsAdapter.setAll(initialState, loadedWorkouts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
                  { type: 'Workout', id: 'LIST' },
                  ...result.ids.map(id => ({ type: 'Workout', id }))
              ]
        } else return [{ type: 'Workout', id: 'LIST' }]
      }
    }),
    createWorkout: builder.mutation({
      query: (workout) => ({
        url: '/workouts',
        method: 'POST',
        body: workout
      }),
      invalidatesTags: [
        { type: 'Workout', id: "LIST" }
      ]
    }),
    updateWorkout: builder.mutation({
      query: (workout) => ({
        url: `/workouts/${workout.id}`,
        method: 'PATCH',
        body: workout
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Workout', id: arg.id }
      ]
    }),
    deleteWorkout: builder.mutation({
      query: (id) => ({
        url: `/workouts/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Workout', id: arg.id }
      ]
    }),
    searchWorkouts: builder.mutation({
      query: (search) => ({
        url: `/workouts/search?timestamp=${new Date()}&${search}`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: response => {
        return response.users;
      }
    }),
    getUserStats: builder.query({
      query: () => ({
        url: `/workouts/user-stats/?timestamp=${new Date()}`,
        method: 'GET'
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success
      },
      transformResponse: response => {
        return response.userStats;
      },
      providesTags: (result, error, arg) => {
        return [{ type: 'UserStats', id: 'LIST' }]
      }
    })
  })
});

export const { useGetWorkoutsQuery, useCreateWorkoutMutation, useUpdateWorkoutMutation, useDeleteWorkoutMutation, useSearchWorkoutsMutation, useGetUserStatsQuery} = workoutsApi;

export const selectWorkoutsresult = workoutsApi.endpoints.getWorkouts.select();

const selectWorkoutsData = createSelector(
  selectWorkoutsresult,
  workoutsResult => workoutsResult.data
);

export const {
  selectAll: selectAllWorkouts,
  selectById: selectWorkoutById,
  selectIds: selectWorkoutIds
} = workoutsAdapter.getSelectors(state => selectWorkoutsData(state) ?? initialState);
import { useGetWorkoutsQuery } from '../../redux/api/workouts.js';
import { Link } from "react-router-dom";
import Workout from "./Workout";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faRotate } from '@fortawesome/free-solid-svg-icons';

const WorkoutsList = () => {
  const { data: workouts, error, isLoading, refetch } = useGetWorkoutsQuery();

  function handleRefetch() {
    refetch()
  }

  if (isLoading) return <><PulseLoader /> Laster inn...</>;
  
  if (error) return <div>Error: {error.message}</div>;

  const { ids } = workouts;
  const tableContent = ids?.length
            ? ids.map(workoutId => <Workout key={workoutId} workoutId={workoutId} />)
            : null

  return (
    <div className='p-3 max-w mx-auto max-w-4xl'>
      <h1 className='text-2xl text-center font-semibold my-7'>Mine treningsøkter</h1>

      <ul role='list' className='flex justify-center mb-5 mt-2 divide-x divide-gray-100'>
        <li className='gap-x-2'>
          <Link to="/workouts/create" style={{display:'block'}}><FontAwesomeIcon icon={faSquarePlus} /> Opprett ny treningsøkt</Link>
        </li>
        <li className='gap-x-2'>
        &nbsp;|&nbsp;
        </li>
        <li className='gap-x-2'>
          <button onClick={handleRefetch}><FontAwesomeIcon icon={faRotate} /> Oppdater</button>
        </li>
      </ul>

      <table className='max-w mb-5'>
        <thead className=''>
          <tr>
            <th scope="col" className=''>Dato</th>
            <th scope="col" className=''>Varighet</th>
            <th scope="col" className=''></th>
          </tr>
        </thead>
        <tbody>
          {tableContent}
        </tbody>
      </table>
      <p>Viser {ids ? ids.length : 0} treningsøkter totalt.</p>
    </div>
  );
}

export default WorkoutsList;
import { useGetAccountQuery } from '../../redux/api/account.js';
import AccountProfileForm from './AccountProfileForm.jsx';
import AccountPasswordForm from './AccountPasswordForm.jsx';
import PulseLoader from 'react-spinners/PulseLoader';

const Account = () => {

  const { data: user, isLoading } = useGetAccountQuery();
  
  if (isLoading) return <><PulseLoader /></>

  const content =
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Min konto</h1>

        <AccountProfileForm user={user} />

        <AccountPasswordForm />
      </div>
    </>

  return content;
}

export default Account;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/auth/authSlice.js';
import { useUpdateAccountMutation } from '../../redux/api/account.js';
import { useSessionDeleteMutation } from '../../redux/api/auth.js';
import ScaleLoader from "react-spinners/ScaleLoader";

const AccountProfileForm = ({ user }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateAccount, { isFetching, isSuccess }] = useUpdateAccountMutation();
  const [sessionDelete] = useSessionDeleteMutation();

  const [firstName, setFirstName] = useState(user.profile.firstName);
  const [lastName, setLastName] = useState(user.profile.lastName);
  const [publicName, setPublicName] = useState(user.profile.publicName);

  useEffect(() => {
    if (isSuccess) {
      setFirstName(user.profile.firstName);
      setLastName(user.profile.lastName);
      setPublicName(user.profile.publicName);
    }
  }, [isSuccess]);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);

  const handleLastNameChange = (e) => setLastName(e.target.value);

  const handlePublicNameChange = (e) => setPublicName(e.target.checked);

  const canSubmit = [firstName, lastName].every(Boolean) && !isFetching;

  const handleLogOut = async () => {
    try {
      const response = await sessionDelete();

      dispatch(logOut());
      navigate('/');
    } catch (error) {
      console.error(error);
      alert('En feil oppstod under utlogging. Prøv igjen senere.');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await updateAccount({ firstName, lastName, publicName });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Personlig informasjon</h2>
            <p className='mt-1 text-sm/6 text-gray-600'>Denne informasjonen er nødvendig å holde oppdatert. Navnet ditt vil kun være synlig for administratorer med mindre du velger å gjøre det synlig for andre.</p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="sm:col-span-3">
                <label htmlFor="firstName" className="block text-sm/6 font-medium text-gray-900">Fornavn</label>
                <div className="mt-2">
                  <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                    <div className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6">
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={handleFirstNameChange}
                        className="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="lastName" className="block text-sm/6 font-medium text-gray-900">Etternavn</label>
                <div className="mt-2">
                  <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                    <div className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6">
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={handleLastNameChange}
                        className="block min-w-0 py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <div className="flex gap-3">
                  <div className="flex h-6 shrink-0 items-center">
                    <div className="group grid size-4 grid-cols-1">
                      <input
                        id="publicName"
                        name="publicName"
                        type="checkbox"
                        aria-describedby="publicName-description"
                        checked={publicName}
                        onChange={handlePublicNameChange}
                        className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                      />
                      <svg
                        fill="none"
                        viewBox="0 0 14 14"
                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      >
                        <path
                          d="M3 8L6 11L11 3.5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:checked]:opacity-100"
                        />
                        <path
                          d="M3 7H11"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:indeterminate]:opacity-100"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="text-sm/6">
                    <label htmlFor="publicName" className="font-medium text-gray-900">
                      Synlig for alle
                    </label>
                    <p id="publicName-description" className="text-gray-500">
                      Som standard er navnet ditt skjult for andre brukere. Dersom du ønsker at navnet ditt skal være synlig for alle, huk av her. Administratorer vil alltid kunne se navnet ditt.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="  px-3 py-2 text-sm font-semibold text-gray-900   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleLogOut}
          >
            Logg ut
          </button>
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >
            {isFetching ? <ScaleLoader color={"#FFF"} /> : 'Oppdater profil'}
          </button>
        </div>

      </form>
    </>
  );
}

export default AccountProfileForm;
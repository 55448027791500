import { useParams } from "react-router-dom";
import logoWide from "../../assets/logo-wide-transparent.png";
import VerifyEmailForm from "./VerifyEmailForm";

const VerifyEmail = () => {

  const { verificationToken } = useParams();

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Kneiken.app"
            src={logoWide}
            className="mx-auto h-14 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Verifiser e-postadressen din
          </h2>
        </div>

        <VerifyEmailForm verificationToken={verificationToken} />        

      </div>
    </>
  )
}

export default VerifyEmail;
import { useSelector } from "react-redux";
//import { selectCurrentUser } from '../redux/auth/authSlice.js';
import { useGetUserStatsQuery, useGetWorkoutsQuery, selectWorkoutById, useDeleteWorkoutMutation } from '../redux/api/workouts.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  //const currentUser = useSelector(selectCurrentUser);

  const [ page ] = useState(1);

  const { data: userStats, refetch: userStatsRefetch } = useGetUserStatsQuery();
  const { data: workouts } = useGetWorkoutsQuery(page);

  
  if (!workouts) return <>Laster...</>

  const { ids } = workouts;
  console.log(ids);
  const tableContent = ids?.length
            ? ids.map(workoutId => <WorkoutItem key={workoutId} workoutId={workoutId} />)
            : null

            console.log(tableContent)

  const handleRefetch = () => userStatsRefetch();

  return (
    <>
      <div className="md:grid md:grid-cols-6 gap-3">
        <div className="col-span-4 p-3">
          <div className="border border-gray-200 p-2 rounded bg-white w-full">
            <h2 className="text-md font-semibold" onClick={handleRefetch}>Mine treningsøkter</h2>

            <table className="w-full table-auto border-separate">
              <thead>
                <tr>
                  <th>Dato</th>
                  <th>Varighet</th>
                  <th>Kilde</th>
                  <th>Notater</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableContent}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-span-2 w-full p-3">
          <div className="border border-gray-200 p-2 rounded bg-white w-full">
            <h2 className="text-md font-semibold" onClick={handleRefetch}>Toppliste medlemmer</h2>
            <ol className="divide-y divide-gray-100">
              {userStats?.map((stat, index) => (
                <li key={index} className="flex justify-between gap-x-2 py-2">
                  <div className="flex min-w-0 gap-x-4">
                    <p className="">{index+1}</p>
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm/6 font-semibold text-gray-900 content-center">{stat.user}</p>
                    </div>
                    <div className="shrink-0 flex flex-col sm:items-end">
                      <p className="text-sm/6 text-gray-900">{stat.workoutCount} stk / {stat.totalDuration} min</p>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}

const WorkoutItem = ({ workoutId }) => {
  const navigate = useNavigate();
  
  const workout = useSelector(state => selectWorkoutById(state, workoutId));
  const [deleteWorkout] = useDeleteWorkoutMutation();

  const handleEdit = () => navigate(`/workouts/${workoutId}/edit`);

  const handleDelete = async () => {
    if (window.confirm('Er du sikker på at du vil slette denne treningsøkten?')) {
      await deleteWorkout(workoutId);
    }
  }

  return (
    workout && (
      <>
      <tr>
        <td>{new Date(workout.workoutDate).toLocaleDateString('nn-NO', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
        <td>{workout.duration} minutter</td>
        <td>{workout.source}</td>
        <td>{workout.notes}</td>
        <td className="text-end">
          <button className="m-1" onClick={handleEdit}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button className="m-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        </td>
    </tr>
    </>
    )
  )
}

export default Dashboard
import { useParams } from "react-router-dom";
import ResetPasswordForm from './ResetPasswordForm';
import logoWide from "../../assets/logo-wide-transparent.png";

const ResetPassword = () => {

  const { resetToken } = useParams();

  const content =
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Kneiken.app"
            src={logoWide}
            className="mx-auto h-14 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Oppdater passordet ditt
          </h2>
        </div>

        <ResetPasswordForm resetToken={resetToken} />

      </div>
    </>

  return content;
}

export default ResetPassword;
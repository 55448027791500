import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

const PasswordChecker = ({password}) => {
  const strength = evaluatePasswordStrength(password);
  
  return (
    <div className="max-w-md pt-2">
      <StrengthIndicator strength={strength} />
      <RequirementsList password={password} />
    </div>
  )
}

const StrengthIndicator = ({ strength }) => {
  const colors = {
    none: 'bg-gray-500',
    weak: 'bg-red-500',
    medium: 'bg-yellow-500',
    strong: 'bg-green-500'
  }

  const translateStrength = {
    none: 'Enkelt',
    weak: 'Svakt',
    medium: 'Middels',
    strong: 'Sterkt'
  }

  return (
    <div className="mt-2">
      <div className="h-2 w-full rounded bg-gray-200">
        <div className={`h-full transition-all duration-300 rounded ${colors[strength]}`} style={{ width: getStrengthPercentage(strength) }} />
      </div>
      <p className="mt-1 text-sm">{translateStrength[strength]} passord</p>
    </div>
  )
}

const RequirementsList = ({ password }) => {

  const requirements = [
    {
      label: 'Minst 8 tegn',
      passed: hasMinimumLength(password)
    },
    {
      label: 'Både små og store bokstaver',
      passed: hasMixedCase(password)
    },
    {
      label: 'Minst ett tall',
      passed: hasNumber(password)
    },
    {
      label: 'Minst ett spesialtegn',
      passed: hasSpecialCharacter(password)
    }
  ]

  return (
    <ul className="mt-4 space-y-1">
      {requirements.map(({ label, passed }) => (
        <RequrementItem key={label} label={label} passed={passed} />
      ))}
    </ul>
  )
}

const RequrementItem = ({ label, passed }) => {
  return (
    <li className={`flex items-center gap-2 text-sm ${passed ? 'text-green-600' : 'text-gray-500'}`}>
      {passed ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircle} />}
      {label}
    </li>
  )
}

const hasMinimumLength = (password) => password.length >= 8;

const hasMixedCase = (password) => /[A-Z]/.test(password) && /[a-z]/.test(password);

const hasNumber = (password) => /[0-9]/.test(password);

const hasSpecialCharacter = (password) => /[^A-Za-z0-9]/.test(password);

const evaluatePasswordStrength = (password) => {
  const checks = [
    hasMinimumLength(password),
    hasMixedCase(password),
    hasNumber(password),
    hasSpecialCharacter(password)
  ]

  const passedChecks = checks.filter(Boolean).length;

  if (passedChecks === 0) return 'none';

  if (passedChecks < 2) return 'weak';

  if (passedChecks < 4) return 'medium';

  return 'strong';
}

const getStrengthPercentage = (strength) => {
  const percentages = {
    none: '0%',
    weak: '33%',
    medium: '66%',
    strong: '100%'
  }
  
  return percentages[strength];
}

export default PasswordChecker;
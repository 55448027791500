import React from 'react'

const ProfileAvatar = ({ firstName, lastName, className, style }) => {

  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);

  return (
    <div className="profile-avatar inline-block size-10 rounded-full ring-2 ring-white bg-blue-300 text-blue-500" style={style}>
      {firstNameInitial}
      {lastNameInitial}
    </div>
  )
}

export default ProfileAvatar
import { useGetGroupsQuery } from '../../redux/api/groups.js';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import GroupListCard from "./GroupListCard.jsx";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faRotate } from '@fortawesome/free-solid-svg-icons';

const GroupsList = () => {
  const roles = useSelector((state) => state.auth.roles);
  const { data: groups, error, isFetching, refetch } = useGetGroupsQuery();

  function handleRefetch() {
    refetch()
  }

  if (error) return <div>Error: {error.message}</div>;

  if (!groups) return <ScaleLoader />

  const { ids } = groups;
  const listContent = ids?.length ? ids.map(groupId => <GroupListCard key={groupId} groupId={groupId} />) : <>Whoha! Her var det ingen grupper! <Link to="/groups/create">Opprett din første gruppe her.</Link></>

  return (
    <div className='p-3 max-w mx-auto max-w-4xl'>
      <h1 className='text-2xl text-center font-semibold my-7'>Grupper</h1>

      <ul role='list' className='flex justify-center mb-5 mt-2 divide-x divide-gray-100'>
        {roles && roles.includes('administrator') && (
          <>
            <li className='gap-x-2'>
              <Link to="/groups/create" style={{display:'block'}}><FontAwesomeIcon icon={faSquarePlus} /> Opprett ny gruppe</Link>
            </li>
            <li className='gap-x-2'>
              &nbsp;|&nbsp;
            </li>
          </>
        )}
        <li className='gap-x-2'>
          <button onClick={handleRefetch}><FontAwesomeIcon icon={faRotate} /> Oppdater</button>
        </li>
      </ul>

      {isFetching ?
        <>
          <div className='flex gap-x-6 p-4 my-2 justify-center'>
            <ScaleLoader />
          </div>
        </>
        : null}
      {listContent && !isFetching ?
        <>
          <div className='mb-5'>
            <ul className="">
              {listContent}
            </ul>
          </div>
        </>
      : null}
      
    </div>
  );
}

export default GroupsList;
import { baseApi } from './base.js';

export const sessionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sessionCreate: builder.mutation({
      query: (credentials) => ({
        url: '/sessions/login',
        method: 'POST',
        body: credentials
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success;
      },
      transformResponse: (response) => {
        console.log(response);
        const loginToken = response.loginToken;
        return loginToken;
      }
    }),
    sessionVerify: builder.mutation({
      query: ({otpCode, loginToken}) => ({
        url: '/sessions/verify',
        method: 'POST',
        body: { otpCode, loginToken }
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && result.success;
      },
      transformResponse: (response) => {
        return response;
      }
    }),
    sessionDelete: builder.mutation({
      query: () => ({
        url: '/sessions/logout',
        method: 'GET'
      })
    })
  }),
});

export const { useSessionCreateMutation, useSessionVerifyMutation, useSessionDeleteMutation } = sessionApi;
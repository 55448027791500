import { useNavigate } from "react-router-dom";
import { useVerifyEmailMutation } from '../../redux/api/account.js';
import { useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Alert from '../../components/Alert.jsx';

const VerifyEmailForm = ({ verificationToken }) => {

  const navigate = useNavigate();

  const [ verifyEmail, { isLoading, isSuccess, error } ] = useVerifyEmailMutation();

  useEffect(() => {
    if (verificationToken) {
      verifyEmail(verificationToken);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      {isLoading && (
          <>
            <div className='flex justify-center font-bold mb-3'>Vennligst vent ...</div>
            <div className='flex justify-center'><ScaleLoader /></div>
          </>
        )}

        {error && (
          <>
            <Alert type='error' message='En feil oppstod under verifisering av e-postadresse. Vennligst prøv igjen, eller ta kontakt med brukerstøtte.' />
          </>
        )}
      </div>
    </>
  )
}

export default VerifyEmailForm;
import { useEffect, useState } from 'react';
import { useSearchWorkoutsMutation } from '../../redux/api/workouts.js';
//import { Link } from "react-router-dom";
//import Workout from "./Workout";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import WorkoutSummaryCard from './WorkoutSummaryCard.jsx';
import { useGetUsersQuery } from '../../redux/api/users.js';

const SearchWorkouts = () => {
  const [ searchWorkouts, { data = [], isLoading, isSuccess } ] = useSearchWorkoutsMutation();
  const { data: users, error, isLoading: isUsersLoading, refetch: refetchUsers } = useGetUsersQuery();

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedUser, setSelectedUser] = useState('');

  const userList = users?.ids.map(id => users?.entities[id]);

  function handleSearch(e) {
    e.preventDefault();

    const query = [];
    if (startDate) query.push(`filterStartDate=${startDate}`);
    if (endDate) query.push(`filterEndDate=${endDate}`);
    if (selectedUser) query.push(`filterUser=${selectedUser}`);
    searchWorkouts(query.join('&'));
  }

  useEffect(() => {
    if (!isSuccess) return;
    console.log(data);
  }, [data]);

  const handleStartDateChange = (e) => setStartDate(e.target.value);

  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const handleUserChange = (e) => setSelectedUser(e.target.value);

  const canSubmit = [startDate, endDate].every(Boolean) && !isLoading;

  const handleUseFilter = (e) => {
    console.log(e);
    /*switch (filter) {
      case 'thisMonth':
        setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]);
        setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0]);
        break;
      case 'lastMonth':
        setStartDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString().split('T')[0]);
        setEndDate(new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString().split('T')[0]);
        break;
      case 'thisYear':
        setStartDate(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
        setEndDate(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]);
        break;
      case 'lastYear':
        setStartDate(new Date(new Date().getFullYear() - 1, 0, 1).toISOString().split('T')[0]);
        setEndDate(new Date(new Date().getFullYear() - 1, 11, 31).toISOString().split('T')[0]);
        break;
    }*/
  }

  return (
    <div className='p-3 max-w mx-auto max-w-4xl'>

      <form onSubmit={handleSearch}>
        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Avansert søk i treningsøkter</h2>
            <p className='mt-1 text-sm/6 text-gray-600'></p>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="sm:col-span-1">
                <label htmlFor="startDate" className="block text-sm/6 font-medium text-gray-900">
                Startdato
                </label>
                <div className="mt-2">
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="endDate" className="block text-sm/6 font-medium text-gray-900">
                Startdato
                </label>
                <div className="mt-2">
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="selectedUser" className="block text-sm/6 font-medium text-gray-900">Type</label>
                <div className="mt-2 grid grid-cols-1">
                  <select
                    id="selectedUser"
                    name="selectedUser"
                    value={selectedUser}
                    onChange={handleUserChange}
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  >
                    <option value=''>(ikke filtrer brukere)</option>
                    {userList.map(user => (
                      <option key={user.id} value={user.id}>{user.profile.firstName} {user.profile.lastName} {('displayName' in user.profile ? `(${user.profile.displayName})` : null )}</option>
                    ))}
                  </select>
                  <FontAwesomeIcon icon={faChevronDown} className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4" />
                </div>
              </div>


            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button type="button" className="text-sm/6 text-gray-600" onClick={handleUseFilter()}>Denne måneden</button>
              <span className="text-sm/6 text-gray-600" onClick={handleUseFilter()}>Forrige måned</span>
              <span className="text-sm/6 text-gray-600" onClick={handleUseFilter()}>Dette året</span>
              <span className="text-sm/6 text-gray-600" onClick={handleUseFilter()}>Forrige år</span>
            </div>
          </div>
          
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >Søk</button>
        </div>
      </form>

      
        {isLoading ? <ScaleLoader color='#6366F1' /> : (
          (data && data.map(user => (
            <>
              <WorkoutSummaryCard data={user} />
            </>
          ))) || <p>Ingen resultater</p>
        )}
      
    </div>
  );
}

export default SearchWorkouts;
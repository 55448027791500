import { useParams } from "react-router-dom";
import { useGetGroupsQuery, groupsApi } from '../../redux/api/groups.js';
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch } from 'react-redux';

const GroupView = () => {
  useTitle('Gruppedetaljer');

  const { id } = useParams();

  const dispatch = useDispatch();

  const { group, refetch } = useGetGroupsQuery('groupsList', {
    selectFromResult: ({ data }) => ({
      group: data?.entities[id]
    })
  });

  

  if (!group) return <PulseLoader color={"#FFF"} />

  return (
    <>
      <div className='p-3 max-w-lg mx-auto'>
        <h1 className='text-2xl font-bold'>{group.name}</h1>

        <p>{group.description}</p>

        <p>{group.members.length}</p>


          <h2>Medlemmer</h2>
          <ul>
            {group.members.map(member => (
              <li key={member.id}>{'displayName' in member.user.profile ? member.user.profile.displayName : `${member.user.profile.firstName} ${member.user.profile.lastName}`}</li>
            ))}
          </ul>
      </div>
    </>
  )
}

export default GroupView;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/auth/authSlice.js';
import { useUpdatePasswordMutation } from '../../redux/api/account.js';
import ScaleLoader from "react-spinners/ScaleLoader";
import PasswordChecker from '../../components/PasswordChecker.jsx';

const AccountPasswordForm = () => {
  const [updatePassword, { isLoading, isSuccess, error }] = useUpdatePasswordMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setOldPassword('');
      setNewPassword('');
      dispatch(logOut());
      navigate('/');
    }
  }, [isSuccess, dispatch, navigate]);

  const onOldPasswordChange = (e) => setOldPassword(e.target.value);

  const onNewPasswordChange = (e) => setNewPassword(e.target.value);

  const canSubmit = [oldPassword, newPassword].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSubmit) {
      await updatePassword({ oldPassword, newPassword });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='mt-10'>

        <div className='space-y-12'>
          <div className='border-b border-gray-900/10 pb-8'>
            <h2 className='text-lg font-semibold'>Påloggingspassord</h2>
            <p className='mt-1 text-sm/6 text-gray-600'>Passordet må være over 8 tegn langt. Etter at du har endret passordet blir du logget ut og må logge på igjen. Du kan ikke endre passord igjen før det har gått minst 5 minutter siden forrige endring.</p>
            <div className="mt-5 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">

              {isLoading ? <ScaleLoader color='#6366F1' /> : (
                <>
                  <div className="sm:col-span-3">  
                    <label htmlFor="oldPassword" className="block text-sm/6 font-medium text-gray-900">Nåværende passord</label>
                    <div className="mt-2">
                      <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                        <div className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6">
                          <input
                            id="oldPassword"
                            name="oldPassword"
                            type="password"
                            placeholder=""
                            value={oldPassword}
                            onChange={onOldPasswordChange}
                            className="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                            autoComplete="current-password"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="newPassword" className="block text-sm/6 font-medium text-gray-900">Nytt passord</label>
                    <div className="mt-2">
                      <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                        <div className="select-none text-base text-gray-500 sm:text-sm/6">
                          <input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            placeholder=""
                            value={newPassword}
                            onChange={onNewPasswordChange}
                            className="block py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <PasswordChecker password={newPassword} />
                    </div>
                  </div>
                </>
              )}

            </div>

            {(error ? (
              <p className="mt-4 text-sm/6 text-red-600">{error?.data?.message}</p>
            ) : null)}
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-[#E29578] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
            disabled={!canSubmit}
          >Endre passord</button>
        </div>

      </form>
    </>
  );
}


export default AccountPasswordForm;
import CreateGroupForm from "./CreateGroupForm";

const CreateGroup = () => {
  
  return (
    <>
      <div className='p-3 max-w mx-auto max-w-4xl'>
        <h1 className='text-2xl text-center font-semibold my-7'>Opprett gruppe</h1>

        <CreateGroupForm />
      </div>
    </>
  )
}

export default CreateGroup;
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout';
//import Public from './components/Public';
import Dashboard from './pages/Dashboard';

import Login from './pages/public/Login.jsx';
import ForgotPassword from './pages/public/ForgotPassword';
import ResetPassword from './pages/public/ResetPassword';
import VerifyOTP from './pages/public/VerifyOTP';
import RegisterUser from './pages/public/RegisterUser.jsx';
import VerifyEmail from './pages/public/VerifyEmail';

import RequireAuth from './features/auth/RequireAuth';
import WorkoutsList from './pages/workouts/WorkoutsList';
import EditWorkout from './pages/workouts/EditWorkout';
import CreateWorkout from './pages/workouts/CreateWorkout';
import SearchWorkouts from './pages/workouts/SearchWorkouts';

import GroupsList from './pages/groups/GroupsList';
import CreateGroup from './pages/groups/CreateGroup';
import EditGroup from './pages/groups/EditGroup';
import GroupView from './pages/groups/GroupView';

import UsersList from './pages/users/UsersList';
//import InviteUser from './pages/users/InviteUser';
import Account from './pages/account/Account';

import Tenant from './pages/tenant/Tenant';
import NotFoundPage from './pages/public/NotFoundPage.jsx';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/*<Route index element={<Public />} />*/}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="login" element={<Login />} />
        <Route path="verify" element={<VerifyOTP />} />
        {/*<Route path="register/:inviteToken" element={<RegisterAccount />} />*/}
        <Route path="register/:shareKey" element={<RegisterUser />} />
        <Route path="verify-email/:verificationToken" element={<VerifyEmail />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:resetToken" element={<ResetPassword />} />
        {/* private routes */}
        <Route element={<RequireAuth />}>
          <Route index element={<Dashboard />} />

          <Route path="workouts">
            <Route index element={<WorkoutsList />} />
            <Route path=":id" element={<EditWorkout />} />
            <Route path="create" element={<CreateWorkout />} />
            <Route path="search" element={<SearchWorkouts />} />
          </Route>

          <Route path="groups">
            <Route index element={<GroupsList />} />
            <Route path=":id/edit" element={<EditGroup />} />
            <Route path=":id" element={<GroupView />} />
            <Route path="create" element={<CreateGroup />} />
          </Route>

          <Route path="users">
            <Route index element={<UsersList />} />
            {/*<Route path="invite" element={<InviteUser />} />*/}
            <Route path="account" element={<Account />} />
          </Route>

          <Route path="account">
            <Route index element={<Account />} />
          </Route>

          <Route path="tenant">
            <Route index element={<Tenant />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
export default function Footer() {
  return (
    <div className='text-center mt-4'>
      <a href="https://support.kneiken.app/" target="_blank" className="text-sm"><small>Brukerstøtte</small></a>
      <span className="mx-2">|</span>
      <a href="mailto:support@kneiken.app" className="text-sm"><small>support@kneiken.app</small></a>
      <br />
      <small>Kneiken.app &copy; 2024</small>
    </div>
  );
}
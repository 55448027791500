import { useGetUsersQuery } from '../../redux/api/users.js';
//import { useGetInvitesQuery } from '../../redux/api/invites.js';
//import { Link } from "react-router-dom";
import User from "./UserCard.jsx";
//import Invite from "./Invite";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faRotate } from '@fortawesome/free-solid-svg-icons';

const UsersList = () => {
  const { data: users, error, isLoading: isUsersLoading, refetch: refetchUsers } = useGetUsersQuery();
  //const { data: invites, isLoading: isInvitesLoading, refetch: refetchInvites } = useGetInvitesQuery();

  console.log(users);

  if (isUsersLoading/* || isInvitesLoading*/) return <><PulseLoader /> Laster inn...</>;

  if (error) return <div>Error: {error.message}</div>;

  const { ids } = users;
  const listContent = ids?.length ? ids.map(userId => <User key={userId} userId={userId} />) : <>Ingen brukere registrert.</>;

  //const { ids: inviteIds } = invites;
  //const invitesList = inviteIds?.length ? inviteIds.map(inviteId => <Invite key={inviteId} inviteId={inviteId} />) : <>Ingen åpne invitasjoner.</>;

  const handleRefetch = () => {
    refetchUsers();
    //refetchInvites();
  }

  return (
    <div className='p-3 max-w mx-auto max-w-4xl'>
      <h1 className='text-2xl text-center font-semibold my-7'>Administrering av medlemmer</h1>

      <ul role='list' className='flex justify-center mb-5 mt-2 divide-x divide-gray-100'>
        {/*<li className='gap-x-2'>
          <Link to="/users/invite" style={{display:'block'}}><FontAwesomeIcon icon={faSquarePlus} /> Inviter nytt medlem</Link>
        </li>*/}
        <li className='gap-x-2'>
        &nbsp;|&nbsp;
        </li>
        <li className='gap-x-2'>
          <button onClick={handleRefetch}><FontAwesomeIcon icon={faRotate} /> Oppdater</button>
        </li>
      </ul>

      <div className='mb-5'>
        {listContent}
      </div>
      {/*}
      <div className='mb-5'>
        <h4 className='text-xl font-bold'>Invitasjoner</h4>
        {invitesList}
      </div>
      {*/}
    </div>
  );
}

export default UsersList;
import RegisterUserForm from "./RegisterUserForm";

const RegisterUser = () => {

  return (
    <>
      <RegisterUserForm />
    </>
  )
}

export default RegisterUser;
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { useState } from "react";
import { logOut } from '../redux/auth/authSlice';
import { useSessionDeleteMutation } from '../redux/api/auth';
//import profilePicture from "../assets/picture-placeholder.png";
import logoWide from "../assets/logo-wide-transparent.png";
import { Button, Menu, MenuButton, MenuItem, MenuItems, MenuSeparator, MenuSection, MenuHeading } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faArrowUpRightFromSquare, faArrowRightFromBracket, faGear, faHandshake, faBars/*, faXmark*/ } from '@fortawesome/free-solid-svg-icons';

import ProfileAvatar from './ProfileAvatar';

export default function Header() {
  //const [active, setActive] = useState("Home");
  //const [toggle, setToggle] = useState(false);

  const currentUser = useSelector((state) => state.auth.user);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const roles = useSelector((state) => state.auth.roles);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ sessionDelete ] = useSessionDeleteMutation();

  const handleSignOut = async () => {
    if (!window.confirm('Er du sikker på at du vil logge ut?')) {
      return;
    }

    try {
      await sessionDelete();

      dispatch(logOut());
      navigate('/');
    } catch (error) {
      console.error(error);
      alert('En feil oppstod under utlogging. Prøv igjen senere.');
    }
    /*try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/sessions/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': process.env.REACT_APP_HOST_URL
        }
      });

      const data = await response.json();
      console.log(data);*/
    
      /*if (data.success === false) {
        throw new Error(data.message);
      }*/

      /*dispatch(setGlobalMessage('Du er nå logget ut.'));
      dispatch(signOut());
      navigate('/sign-in');
    } catch (error) {
      console.error(error);
      alert('En feil oppstod under utlogging. Prøv igjen senere.');
    }*/
  }

  return (
    <nav className="bg-white dark:bg-gray-900 w-full border-gray-200 dark:border-gray-600">
      <div className="flex justify-between sm:items-end md:items-center max-w-6xl mx-auto p-2">
        <Link to="/" className='justify-start'>
          <img src={logoWide} style={{height:38}} className="App-logo" alt="kneiken.app Logo" />
        </Link>
        {accessToken ? (
          <>
            <div className="flex justify-end md:hidden">
              <Menu>
                <MenuButton>
                  <FontAwesomeIcon icon={faBars} className='text-white' />
                </MenuButton>
                <MenuItems anchor="top" transition className="p-2 rounded w-52 transition duration-200 ease-out bg-white shadow-sm">
                  <MenuItem>
                    <Link to="/workouts/" className="block data-[focus]:bg-blue-100">Treningsøkter</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/groups/" className="block data-[focus]:bg-blue-100">Grupper</Link>
                  </MenuItem>
                  {roles && roles.includes('administrator') && (
                    <>
                      <MenuItem>
                        <Link to="/tenant/" className="block data-[focus]:bg-blue-100">Organisasjon</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/users/" className="block data-[focus]:bg-blue-100">Medlemmer</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/workouts/search" className="block data-[focus]:bg-blue-100">Søk</Link>
                      </MenuItem>
                    </>
                  )}
                </MenuItems>
              </Menu>
            </div>
            <div className="hidden md:flex gap-4 justify-center content-center items-center">

              <Link to="/workouts/create" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <Button >
                  <FontAwesomeIcon icon={faSquarePlus} className="mr-2" />
                </Button>
              </Link>
              <Link to="/workouts" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Treningsøkter</Link>
              <Link to="/groups" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Grupper</Link>
              {roles && roles.includes('administrator') && (
                <>
                  <Link to="/tenant" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Organisasjon</Link>
                  <Link to="/users" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Medlemmer</Link>
                  <Link to="/workouts/search" className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Søk</Link>
                </>
              )}
            </div>
            <Menu>
              <MenuButton>
                <ProfileAvatar className='profile-avatar bg-blue-300 text-blue-500' style={{ "--avatar-size": "32px"}} firstName={currentUser.firstName} lastName={currentUser.lastName} />
              </MenuButton>
              <MenuItems anchor="bottom" transition className="p-2 rounded w-52 transition duration-200 ease-out bg-white shadow-sm">
                <MenuSection>
                  <MenuHeading className="text-sm opacity-50">Logget inn som<br /><b>{currentUser.firstName} {currentUser.lastName}</b></MenuHeading>
                  <MenuSeparator className="my-1 h-px bg-slate-300" />
                  <MenuItem>
                    <Link to="/account" className="block data-[focus]:bg-blue-100">Min konto <FontAwesomeIcon icon={faGear} className='ml-2' /></Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/account" className="block data-[focus]:bg-blue-100">Avtaler <FontAwesomeIcon icon={faHandshake} className='ml-2' /></Link>
                  </MenuItem>
                  <MenuItem>
                    <a className="block data-[focus]:bg-blue-100" href="https://support.kneiken.app/" target="_blank" rel="noreferrer">
                      Support <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" />
                    </a>
                  </MenuItem>
                  <MenuSeparator className="my-1 h-px bg-slate-300" />
                  <MenuItem>
                    <button className="block data-[focus]:bg-blue-100" onClick={handleSignOut}>
                      Logg av <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2" />
                    </button>
                  </MenuItem>
                </MenuSection>
              </MenuItems>
            </Menu>
          </>
        ) : (
          <>
            
          </>
        )}
      </div>
    </nav>
  );
}
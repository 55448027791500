const Badge = ({ title }) => {
  return (
    <>
      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 ms-2">
        {title}
      </span>
    </>
  )
}

export default Badge